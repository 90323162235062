<template>
  <div id="viewStudentJoined">
    <!-- breadCrumb -->
    <v-breadcrumbs :items="breadcrumbList" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <!-- breadCrumb -->
    <v-row v-if="ExamData && ExamData.exam_details">
      <v-col cols="12">
        <div class="title">
          <h1>{{ $t("Class") }} {{ ExamData.exam_details.class_name }}</h1>
        </div>
        <div class="title">
          <h2 class="main-color">
            {{ ExamData.exam_details.exam_name }} -
            {{ ExamData.exam_details.subject_name }}
          </h2>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="ExamData && ExamData.exam_details">
      <v-col cols="12" md="8" offset-md="2" class="info-list">
        <ul class="inline">
          <li>
            <strong> {{ $t("Date") }}: </strong>{{ ExamData.exam_details.date }}
          </li>
          <li>
            <strong> {{ $t("Academic Period") }}:</strong>
            {{ ExamData.exam_details.year_name }}
          </li>
          <li>
            <strong> {{ $t("Qualifier") }}:</strong>
            {{ ExamData.exam_details.qualifier_name }}
          </li>
        </ul>
      </v-col>

      <v-col cols="12" md="12" class="hr-div">
        <hr />
      </v-col>
    </v-row>
    <!-- Student -->
    <v-row>
      <v-col cols="12" md="4">
        <div class="title mb-10">
          <h2 class="main-color text-center dot redDot">
            {{ $t("Students Not Joined Exam Yet") }}
          </h2>
        </div>
        <div class="students notjoined">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">{{ $t("Student Name") }}</th>
                  <th class="text-left">{{ $t("Student Code") }}</th>
                </tr>
              </thead>
              <tbody v-if="ExamData.students">
                <tr
                  v-for="(notJoinedStudent, index) in ExamData.students
                    .not_joined"
                  :key="index"
                >
                  <td>
                    <StudentHoverMenu
                      :studentName="notJoinedStudent.name"
                      :studentId="notJoinedStudent.id"
                      :userId="notJoinedStudent.user_id"
                      v-if="notJoinedStudent.id"
                    ></StudentHoverMenu>
                  </td>
                  <td>{{ notJoinedStudent.code }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-col>
      <v-col cols="12" md="4" style="border-right: 1px solid #ddd">
        <div class="title mb-10">
          <h2 class="main-color text-center dot greenDot">
            {{ $t("Students Joined Exam") }}
          </h2>
        </div>
        <div class="students joined">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">{{ $t("Student Name") }}</th>
                  <th class="text-left">{{ $t("Joined At") }}</th>
                  <th class="text-left">{{ $t("Joined From") }}</th>
                </tr>
              </thead>
              <tbody v-if="ExamData.students">
                <tr
                  v-for="(joinedStudent, index) in ExamData.students.joined"
                  :key="index"
                >
                  <td>{{ joinedStudent.name }}</td>
                  <td>{{ joinedStudent.created_at }}</td>
                  <td>{{ joinedStudent.platform }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-col>
      <v-col cols="12" md="4" style="border-right: 1px solid #ddd">
        <div class="title mb-10">
          <h2 class="main-color text-center">
            {{ $t("Students Submitted Exam") }}
            <v-icon style="height: 26px" class="main-color" large
              >check_box</v-icon
            >
          </h2>
        </div>
        <div class="students joined">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">{{ $t("Student Name") }}</th>
                  <th class="text-left">{{ $t("Submitted At") }}</th>
                  <th class="text-left">{{ $t("Actions") }}</th>
                </tr>
              </thead>
              <tbody v-if="ExamData.students">
                <tr
                  v-for="(submittedStudent, index) in ExamData.students
                    .submitted"
                  :key="index"
                >
                  <td>{{ submittedStudent.name }}</td>
                  <td>{{ submittedStudent.submitted_at }}</td>
                  <td>
                    <v-icon
                      v-if="ExamData.exam_details.show_delete_btn"
                      @click="deleteSubmittedExam(submittedStudent.code)"
                      >delete</v-icon
                    >
                    <router-link
                      :to="
                        '/examCorrection/' +
                        academicYear +
                        '/' +
                        classId +
                        '/' +
                        examId +
                        '/' +
                        submittedStudent.id
                      "
                      :title="$t('Student Answers')"
                    >
                      <v-icon>assignment</v-icon>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import StudentHoverMenu from "@/components/Students/StudentHoverMenu";
// import ACL from "../../acl";

export default {
  name: "viewStudentJoined",
  components: { StudentHoverMenu },
  data() {
    return {
      valid: true,
      snackbar: false,
      color: "",
      text: "",
      ExamData: {},
      academicYear: "",
      classId: "",
      examId: "",
    };
  },

  methods: {
    getExamData() {
      axios
        .get(this.getApiUrl + "/exams/students/" + this.examId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.ExamData = response.data.data;
        });
    },
    deleteSubmittedExam(studentCode) {
      axios
        .get(
          this.getApiUrl +
            "/exams/" +
            this.examId +
            "/delete-submitted/" +
            studentCode,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) this.getExamData();
        });
    },
  },
  mounted() {
    // this.getExamData();
    console.log(this.$socket);
    // if (window.location.protocol == "https:") {
    const _this = this;
    if (process.env.VUE_APP_SOCKET_URL != undefined) {
      console.log("channel is ready");
      this.$socket
        .channel("StudentJoinedExam")
        .listen("StudentJoinedExam", (e) => {
          console.log("StudentJoinedExam", e);
          console.log("_this.examId", _this.examId);
          if (parseInt(e.exam_id) == parseInt(_this.examId)) {
            _this.getExamData();
          }
        });
    }
    // }
  },
  created() {
    this.academicYear = this.$router.currentRoute.params.academicYear;
    this.classId = this.$router.currentRoute.params.classId;
    this.examId = this.$router.currentRoute.params.examId;
    this.getExamData();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_forms.scss";
.title h2 {
  margin-bottom: 0 !important;
  font-size: 1.5rem;
}
.info-list {
  padding-top: 0;
  padding-bottom: 0;
  li {
    display: inline-block;
    width: 33%;
    font-size: 13px;
    padding-right: 2px;
  }
}

@media (max-width: 600px) {
  .info-list {
    li {
      font-size: 10px;
    }
  }
}

.icons-div,
.hr-div {
  padding-top: 0;
  padding-bottom: 0;

  hr {
    margin-bottom: 0 !important;
    margin-top: 0.5rem !important;
  }
}

.table-grid {
  padding-top: 0 !important;
}

button.ui-btn.submit:disabled {
  cursor: not-allowed;
}
.dot {
  position: relative;
}
.dot::after {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  border-radius: 50%;
  bottom: 5px;
  margin-left: 10px;
}
.greenDot::after {
  background-color: #31a24c;
}
.redDot::after {
  background-color: #e44b11;
}
</style>
